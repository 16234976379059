import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import notify from '~/utils/notify';
import delay from 'delay';

export const MSG_TYPE_SERVER_HEARTBEAT = 4000; // 心跳
export const MSG_TYPE_SERVER_WS_CLOSE = 5000; // 服务端主动关闭连接
export const MSG_TYPE_CLIENT_WS_CLOSE_ACK = 6000; // 客户端主动关闭连接确认
export const MSG_TYPE_SERVER_PUSH_ONLINE_MSG = 200; // 服务端推送在线消息
export const MSG_TYPE_SERVER_PUSH_OFFLINE_MSG = 201; // 服务端推送离线消息

export const MSG_CONTENT_TYPE_TEXT = 1; // 文字消息
export const MSG_CONTENT_TYPE_IMAGE_URL = 2; // 图片消息(链接)

export const CHANNEL_TYPE_PRIVATE = 1; // 私聊
export const CHANNEL_TYPE_CHANNEL = 2; // 频道
export const CHANNEL_TYPE_BROADCAST = 3; // 广播

// ws状态
export const WS_STATUS_CONNECTING = 0;// 连接中
export const WS_STATUS_OPEN = 1;// 已经链接并且可以通讯
export const WS_STATUS_CLOSING = 2;// 连接正在关闭
export const WS_STATUS_CLOSED = 3;// 连接已关闭或者没有链接成功

class imClient {
    constructor({
                    wsAddr = '',
                    httpAddr = '',
                    imOpenId = '',
                    sessionId = '',
                    loginInfo = '',
                    personDebug = false,
                }) {
        this._setConst();
        this.id = uuidv4();// 实例ID
        this._debug = false;// 是否输出debug信息
        this._reconnectIntervalMs = 10 * 1000;// ws重连间隔
        this._wsAddr = wsAddr;// ws地址
        this._httpAddr = httpAddr;// http地址
        this._closed = false;
        this._sessionId = sessionId;
        this._loginInfo = loginInfo;
        this._imOpenId = imOpenId;
        this._personDebug = personDebug;

        if (this._personDebug === true) {
            console.log('开启调试模式');
        }

        // 心跳设置
        this._needReconn = true; // 是否需要重连
        this._connectIng = false;// 是否重连中
        this._reconnectIng = false;// 是否重连中
        this._heartBeatInterval = null; // 心跳实例

        // 接收到推送消息的回调函数
        this._onReceiveMsgFunc = ({
                                      msg, source,
                                  }) => {
            this._infoLog('receive msg>>>', msg);
            this._infoLog('receive msg [source]>>>', source);
        };

        // ws连接实例
        this._wsConn = null;

        this._logReport(`:constructor [imOpenId:${imOpenId}],`);
    }

    async _logReport(logStr) {
        if (this._personDebug !== true) {
            return;
        }
        // await logReport(logStr);
        console.log('log report', logStr)
    }

    _setConst() {
        this.MSG_TYPE_SERVER_HEARTBEAT = MSG_TYPE_SERVER_HEARTBEAT;
        this.MSG_TYPE_SERVER_WS_CLOSE = MSG_TYPE_SERVER_WS_CLOSE;
        this.MSG_TYPE_CLIENT_WS_CLOSE_ACK = MSG_TYPE_CLIENT_WS_CLOSE_ACK;
        this.MSG_TYPE_SERVER_PUSH_ONLINE_MSG = MSG_TYPE_SERVER_PUSH_ONLINE_MSG;
        this.MSG_TYPE_SERVER_PUSH_OFFLINE_MSG = MSG_TYPE_SERVER_PUSH_OFFLINE_MSG;

        this.MSG_CONTENT_TYPE_TEXT = MSG_CONTENT_TYPE_TEXT;
        this.MSG_CONTENT_TYPE_IMAGE_URL = MSG_CONTENT_TYPE_IMAGE_URL;

        this.CHANNEL_TYPE_PRIVATE = CHANNEL_TYPE_PRIVATE;
        this.CHANNEL_TYPE_CHANNEL = CHANNEL_TYPE_CHANNEL;
        this.CHANNEL_TYPE_BROADCAST = CHANNEL_TYPE_BROADCAST;
    }

    // 建立连接
    async connect() {
        await this._logReport(':async connect');
        await this._logReport(`:this._connectIng ${this._connectIng}`);
        if (this._connectIng) {
            return;
        }

        this._connectIng = true;

        this._needReconn = true;

        this._closed = false;

        const connectRes = await this._connect(this._loginInfo);

        this._connectIng = false;

        this._startHeartBeatInterval();

        return connectRes;
    }

    async _reconnect(source) {
        this._infoLog('reconnect by:', source);
        this._infoLog('this._reconnectIng:', this._reconnectIng);
        if (this._reconnectIng) {
            return;
        }
        this._reconnectIng = true;

        for (let i = 0; ; i++) {
            this._infoLog('reconnect .... , times:', i);

            // 关闭当前连接
            this.close();

            // 从第二次开始 等待10秒
            if (i > 0) {
                await delay(this._reconnectIntervalMs);
            }

            const {
                error, data,
            } = await this._connect();
            if (error) {
                console.log('reconnect fail');
                continue;
            }

            this._infoLog('reconnect success');
            this._startHeartBeatInterval();
            break;
        }

        this._reconnectIng = false;
    }

    async _connect() {
        return new Promise((resolve, reject) => {
            const wsUrl = `${this._wsAddr}/im/connect?token=${this._loginInfo.access_token}&session=${this._sessionId}`;
            this._infoLog('im 建立连接: ', wsUrl);

            const ws = new WebSocket(wsUrl);

            ws.onopen = (evt) => {
                this._infoLog('im连接成功');
                this._wsConn = ws;
                resolve({
                    error: null, data: 'success',
                });
            };

            ws.onerror = (evt) => {
                this._infoLog('!!im 连接异常!!', evt);
                reject({
                    error: 'im error', data: evt,
                });
            };

            ws.onmessage = (evt) => {
                // 只接收 msg_code == 200的消息
                this._infoLog('receive msg:', evt.data);
                try {
                    const jsonMsg = JSON.parse(evt.data);
                    const {
                        msg_code, msg_type, to_id,
                    } = jsonMsg;
                    // 系统消息暂不处理
                    if (msg_type > 1000) {
                        return;
                    }
                    switch (msg_code) {
                        case MSG_TYPE_SERVER_PUSH_ONLINE_MSG:
                            this._onReceiveMsgFunc({roomId: to_id, msg: jsonMsg});
                            break;
                        case MSG_TYPE_SERVER_HEARTBEAT:
                            break;
                        case MSG_TYPE_SERVER_WS_CLOSE:
                            console.log('>>>>>>>>>>>im server: ws conn close event.<<<<<<<<<<<<');
                            this._needReconn = false;
                            this.close();
                            break;
                        default:
                            return;
                    }
                } catch (e) {
                    console.log('im receive err:', e);
                }
            };

            ws.onclose = (evt) => {
                console.log('websocket close:', evt);

                if (this._needReconn) {
                    this._reconnect('ws.onclose');
                }
            };
        });
    }

    wsConnIsAvailable() {
        this._logReport(`:async wsConnIsAvailable [this._wsConn: ${this._wsConn}]`);

        if (!this._wsConn) {
            return false;
        }

        this._logReport(`:async wsConnIsAvailable -> res[this._wsConn.readyState === WS_STATUS_OPEN: ${this._wsConn.readyState === WS_STATUS_OPEN}]`);
        return this._wsConn.readyState === WS_STATUS_OPEN;
    }

    async sendTextMsg(roomId, msg) {
        await this._logReport(`:async sendGroupTextMsg -> [${msg}]`);

        this._infoLog('发送[群组文字]消息', msg);

        const pureAxios = axios.create();
        return pureAxios({
            method: 'post',
            url: `${this._httpAddr}/api/messages/group`,
            headers: {
                authorization: this._loginInfo.access_token,
            },
            data: {
                msg_client_id: uuidv4(), // 发消息的唯一ID
                to_id: roomId, // 收消息的群组ID
                msg_type: MSG_CONTENT_TYPE_TEXT, message: msg, channel_type: CHANNEL_TYPE_CHANNEL,
            },
        })
            .then((resp) => {
                const {code} = resp.data;
                if (code < 0) {
                    notify.err('send text message fail');
                    this._logReport(':async sendGroupTextMsg -> fail');
                    return resp.data.msg;
                }

                this._logReport(':async sendGroupTextMsg -> success');

                return true;
            })
            .catch((err) => {
                console.log('/api/messages/group', err);
                this._logReport(`:async sendGroupTextMsg -> error [${err.toString()}]`);
                return false;
            });
    }

    async sendGroupImgMsg(roomId, msg) {
        await this._logReport(`:async sendGroupImgMsg ${msg}`);

        this._infoLog('发送[群组图片]消息', msg);

        const pureAxios = axios.create();
        return pureAxios({
            method: 'post',
            url: `${this._httpAddr}/api/messages/group`,
            headers: {
                authorization: this._loginInfo.access_token,
            },
            data: {
                msg_client_id: uuidv4(), // 发消息的唯一ID
                to_id: roomId, // 收消息的群组ID
                msg_type: MSG_CONTENT_TYPE_IMAGE_URL, message: msg, channel_type: CHANNEL_TYPE_CHANNEL,
            },
        })
            .then((resp) => {
                const {code} = resp.data;
                if (code < 0) {
                    notify.err('send img message fail');
                    this._logReport(':async sendGroupImgMsg -> fail');
                    return resp.data.msg;
                }
                this._logReport(':async sendGroupImgMsg -> success');

                return true;
            })
            .catch((err) => {
                console.log('/api/messages/group', err);
                this._logReport(`:async sendGroupImgMsg -> error [${err.toString()}]`);
                return false;
            });
    }

    // 收到推送消息的回调
    setOnReceiveMsgFunc(fn) {
        this._onReceiveMsgFunc = fn;
    }

    // 拉取历史消息
    async fetchHistoryMsgByHttp(groupID, sendTime) {
        await this._logReport(`:async fetchHistoryMsgByHttp [groupID:${groupID}|imJwtToken:${this._loginInfo.access_token}]`);

        const pureAxios = axios.create();

        return pureAxios({
            method: 'get',
            url: `${this._httpAddr}/api/messages/groups`,
            headers: {
                authorization: this._loginInfo.access_token,
            },
            params: {
                send_time: sendTime,
                to_id: groupID,
            },
        }).then((resp) => {
            if (!resp) {
                return [];
            }
            if (resp.data.code < 0) {
                return [];
            }

            if (!resp.data.data) {
                return [];
            }

            this._logReport(':async fetchHistoryMsgByHttp -> success');
            return resp.data.data;
        }).catch((error) => {
            this._logReport(`:async fetchHistoryMsgByHttp -> error ${error.toString()}`);
            console.log('err', error);
            return [];
        });
    }

    // 开始心跳
    _startHeartBeatInterval() {
        this._logReport(':_startHeartBeatInterval');

        this._stopHeartBeatInterval();
        // 先关闭当前心跳, 1秒后开始新的心跳
        // 20秒一次心跳
        setTimeout(() => {
            this._heartBeatInterval = setInterval(async () => {
                await this._logReport(':_startHeartBeatInterval -> interval -> run');
                this._heartBeat();
            }, 10000);
        }, 1000);
    }

    // 发送心跳
    async _heartBeat() {
        await this._logReport(':_heartBeat');

        if (!this._wsConn) {
            await this._logReport(':_heartBeat [没有连接实例 this._wsConn is null]');
            this._infoLog('没有连接实例 this._wsConn is null');
            this._stopHeartBeatInterval();
            return;
        }
        if (this._wsConn.readyState !== WS_STATUS_OPEN) {
            await this._logReport(`:_heartBeat [ws 连接不可用,当前状态:${this._wsConn.readyState}]`);
            this._infoLog('ws 连接不可用,当前状态:', this._wsConn.readyState);
            this._reconnect('this._wsConn.send(MSG_TYPE_SERVER_HEARTBEAT)');
            return;
        }

        await this._logReport(':_heartBeat -> send');

        this._wsConn.send(JSON.stringify({msg_code: MSG_TYPE_SERVER_HEARTBEAT}),);
    }

    // 关闭连接
    async close() {
        await this._logReport(':async close');

        this._infoLog('>>>ws connect close<<<');

        if (this._wsConn) {
            this._wsConn.close();
        }
        this._wsConn = null;

        this._closed = true;

        this._needReconn = false;

        this._stopHeartBeatInterval();
    }

    // 关闭心跳
    async _stopHeartBeatInterval() {
        await this._logReport(':_stopHeartBeatInterval [尝试关闭旧心跳]');

        if (!this._heartBeatInterval) {
            return;
        }
        clearInterval(this._heartBeatInterval);
    }

    setDebug(debug) {
        this._debug = debug;
        return this;
    }

    _infoLog(...args) {
        if (this._debug) {
            console.log(`[ID:${this.id}]`, ...args);
        }
    }
}

export default imClient;
