import {LANG_ZH_CN, LANG_ZH_TC, LANG_EN} from "~/consts/const";

class browser {
    static getLang() {
        const lang = window.navigator.language || window.navigator.userLanguage;
        if (/^[zh|Zh|zH|ZH]/.test(lang)) {
            switch (lang) {
                case 'zh_tc':
                case 'zh_hk':
                case 'zh_sc':
                    return LANG_ZH_TC;
                default:
                    return LANG_ZH_CN;
            }
        }

        if (/^en/.test(lang)) {
            return LANG_EN;
        }

        return LANG_ZH_CN;
    }

    static bodyScrollToBottom() {
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        document.documentElement.scrollTop = scrollHeight;
        document.body.scrollTop = scrollHeight;
    }
}

export default browser;
