import {message, notification} from "ant-design-vue";
import {h} from "vue";
import LangText from "~/components/lang/LangText";
import copy from "copy-to-clipboard";
import isString from 'lodash/isString';

class notify {
    static success = (msgKey = '', descKey = '') => {
        notification.success({
            message: () => h(LangText, {
                msgKey: msgKey,
                popoverPosition: 'bottom',
            }),
            description: descKey ? h(LangText, {
                msgKey: descKey,
            }) : '',
        });
    }
    static errCustom = (msg, desc) => {
        notification.error({
            message: msg,
            description: desc,
        })
    }

    static err = (msgKey) => {
        notification.error({
            message: h(LangText, {
                msgKey: msgKey,
                popoverPosition: 'bottom',
            }),
        });
    }

    static apiErr = (resp = {}) => {
        const {TraceId, Code, Data} = resp.data;
        notification.error({
            message: h(LangText, {
                msgKey: Code,
            }),
            description: Data,
            onClick: () => {
                if (!TraceId) {
                    return
                }
                copy(TraceId);
                message.success('error id copied!')
            }
        });
    }
}

export default notify;
