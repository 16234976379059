import {customAlphabet} from 'nanoid'

const nanoIdBaseCode = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

// UID长度预设值, 每生成N个 UID 有%1的碰撞率
// 碰撞计算器 https://zelark.github.io/nano-id-cc/
export const NANO_ID_LEN_SAFE_4K = 5; //4K
export const NANO_ID_LEN_SAFE_33K = 6; //33K
export const NANO_ID_LEN_SAFE_129M = 10;// 129M个
export const NANO_ID_LEN_SAFE_30T = 16; // 30T个

class uuid {
    static id(len = NANO_ID_LEN_SAFE_129M) {
        return customAlphabet(nanoIdBaseCode, len)()
    }

}

export default uuid;
