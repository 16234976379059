import validate from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45init_45global from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/middleware/0.init.global.js";
import _1_45common_45global from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/middleware/1.common.global.js";
import _2_45im_45close_45global from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/middleware/2.im-close.global.js";
import _3_45unread_45station_45letters_45global from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/middleware/3.unread-station-letters.global.js";
import manifest_45route_45rule from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45init_45global,
  _1_45common_45global,
  _2_45im_45close_45global,
  _3_45unread_45station_45letters_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}