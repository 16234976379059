import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

//公式
// import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';

//流程图
// import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
// import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';

import Prism from 'prismjs';

//checkbox
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});

VMdPreview.use(vuepressTheme, {
    Prism,
});

VueMarkdownEditor.use(createTodoListPlugin());
// VueMarkdownEditor.use(createKatexPlugin());
// VueMarkdownEditor.use(createMermaidPlugin());

VMdPreview.use(createTodoListPlugin());
// VMdPreview.use(createKatexPlugin());
// VMdPreview.use(createMermaidPlugin());

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.use(VueMarkdownEditor);
    nuxtApp.vueApp.use(VMdPreview);
})


