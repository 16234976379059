<script setup>
import {LangStore} from "~/common-store/lang-store";
import {EditModeStore} from "~/common-store/edit-mode-store";
import {message, Popover} from 'ant-design-vue';
import {EnvStore} from "~/common-store/env-store";
import {TipsStore} from "~/common-store/tips-store";
import {InformationCircleIcon} from '@heroicons/vue/20/solid'
import {Divider} from 'ant-design-vue';
import Tooltip from "~/components/tooltip/Tooltip";
import {HELP_TIP_TYPE_TIP, HELP_TIP_TYPE_LINK} from '~/consts/const';
import Link from "~/components/link/Link";
import {UserStore} from "~/store/user-store";
import {onMounted, ref} from "vue";
import copy from "copy-to-clipboard";


const editModeStore = EditModeStore();

const conf = EnvStore().config;

const langStore = LangStore();

const tipsStore = TipsStore();

const userStore = UserStore();

const props = defineProps({
  msgKey: {
    type: String,
    default: '',
  },
  tipKey: {
    type: String,
    default: '',
  },
  //topLeft | top | topRight | leftTop | left | leftBottom | rightTop | right | rightBottom | bottomLeft | bottom | bottomRight |
  popoverPosition: {
    type: String,
    default: 'top',
  },
  customClass: {
    type: String,
    default: '',
  },
});

const tip = tipsStore.t(props.tipKey);
const tooltipContent = () => {
  if (tip.type === HELP_TIP_TYPE_LINK) {
    return langStore.t('点击查看帮助信息')
  }
  return tip.content;
}

const jwt = ref('');
onMounted(async () => {
  jwt.value = await userStore.jwt();
})

</script>

<template>
  <div
      :class="[customClass ? customClass :'','flex']"
      v-show="!editModeStore.editModeEnable"
  >
    {{ langStore.t(msgKey) }}
    <Tooltip
        v-if="tip"
        :content="tooltipContent()"
        placement="topRight"
    >
      <Link
          v-if="tip.type === HELP_TIP_TYPE_LINK"
          class="flex"
          :to="`${conf.TipsHost}/tips/detail?id=${tipKey}&l=${langStore.defaultLang}&p=${conf.Platform}&jwt=${jwt}`"
          :external="true"
          :targetBlank="true"
      >
        <InformationCircleIcon class="w-5 ml-1"/>
      </Link>

      <InformationCircleIcon v-else class="w-5 ml-1"/>
    </Tooltip>
  </div>

  <div
      :class="[customClass ? customClass :'','flex']"
      v-show="editModeStore.editModeEnable"
  >
    <Popover
        :placement="popoverPosition"
        :overlayStyle="{zIndex:9999}"
    >
      <template #content>
        词条编辑
        <div>
          <div v-if="!langStore.available(msgKey)">key: 未设置</div>
          <div
              v-else
              class="cursor-pointer"
              @click="()=>{
                copy(msgKey)
                message.success('词条key已复制')
              }"
          >
            key: {{ msgKey }} (点击复制)
          </div>
          简体中文: {{ langStore.t(msgKey) }}
        </div>
        <Divider class="m-1"/>

        <div v-if="tipKey">
          Tip edit
          <p>
            tip key: {{ tipKey }}
          </p>
        </div>
        <div v-else>帮助信息不可编辑</div>

      </template>
      <div>
        <div :class="[customClass ? customClass : '','flex']">
          {{ langStore.t(msgKey) }}
          <InformationCircleIcon class="w-5 ml-1"/>
        </div>
      </div>
    </Popover>
  </div>

</template>
