import { default as child_45commission_45ratio_45update_45modaldiY2sqhdOCMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/child-commission-ratio-update-modal.vue?macro=true";
import { default as child_45merch_45agent_45storekzKsJGG2wqMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/child-merch-agent-store.js?macro=true";
import { default as child_45remark_45update_45modalJy0oqTWh7AMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/child-remark-update-modal.vue?macro=true";
import { default as pagexzA8HTJoz1Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/page.vue?macro=true";
import { default as child_45merch_45storeboqhhYYHiSMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch/child-merch-store.js?macro=true";
import { default as child_45remark_45update_45modalRcHqPW9fzlMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch/child-remark-update-modal.vue?macro=true";
import { default as pageBpcvUuQAd5Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch/page.vue?macro=true";
import { default as home_45storelONsp7xYpEMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/home/home-store.js?macro=true";
import { default as pagetvMtt07dyXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/home/page.vue?macro=true";
import { default as mobile9iaduMO4D5Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/im/mobile.vue?macro=true";
import { default as page87ZSY9swknMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/im/page.vue?macro=true";
import { default as pcWvUKcqGWnNMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/im/pc.vue?macro=true";
import { default as index7NXQwNSzMhMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/index.vue?macro=true";
import { default as login_45store5IBr3ro87YMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/login/login-store.js?macro=true";
import { default as pagekKyVX7Y36rMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/login/page.vue?macro=true";
import { default as my_45commission_45storeS6sR7nDI0cMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/my-commission-store.js?macro=true";
import { default as pageY5sgYAZhsYMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/page.vue?macro=true";
import { default as withdraw_45modal_45rmb_45storezvJ5EDUBf8Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/withdraw-modal-rmb-store.js?macro=true";
import { default as withdraw_45modal_45usdt_45storeuXgv5H4KKiMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/withdraw-modal-usdt-store.js?macro=true";
import { default as WithdrawModalRmbnsqdCOCmwYMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/WithdrawModalRmb.vue?macro=true";
import { default as WithdrawModalUsdtUuCR9NHO2YMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/WithdrawModalUsdt.vue?macro=true";
import { default as my_45fee_45storeGhTpp1GYnoMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-fee/my-fee-store.js?macro=true";
import { default as pagegcfZKCmDZbMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-fee/page.vue?macro=true";
import { default as my_45merchant_45store3aU6RQYENgMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-merchant/my-merchant-store.js?macro=true";
import { default as page4YHkNeuMxLMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-merchant/page.vue?macro=true";
import { default as pagezcFKVzOmKjMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/register/page.vue?macro=true";
import { default as register_45storen4hSUsFhPqMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/register/register-store.js?macro=true";
import { default as pageR3B9rBTmtMMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/settings/page.vue?macro=true";
import { default as settings_45storebpAfriwc5fMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/settings/settings-store.js?macro=true";
import { default as detailyTfRttnybsMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/detail.vue?macro=true";
import { default as pagevrmbmo74EoMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/page.vue?macro=true";
import { default as station_45letter_45detail_45storeRY9c8zrsTrMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/station-letter-detail-store.js?macro=true";
import { default as station_45letter_45storedGhdgsjifGMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/station-letter-store.js?macro=true";
import { default as page5z0w2bYwI2Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/test/page.vue?macro=true";
import { default as pagenzQOvi1sOOMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/test1/page.vue?macro=true";
import { default as test1_45storejXJ4FPdvliMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/test1/test1-store.js?macro=true";
import { default as pageEoQZIsAySJMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/user-center/page.vue?macro=true";
import { default as user_45center_45store46QDboEoOCMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/user-center/user-center-store.js?macro=true";
import { default as pageEti39IXmTcMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/wallet/usdt-bill/page.vue?macro=true";
import { default as usdt_45bill_45storefETcuC01PsMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/wallet/usdt-bill/usdt-bill-store.js?macro=true";
export default [
  {
    name: "child-merch-agent-child-commission-ratio-update-modal",
    path: "/child-merch-agent/child-commission-ratio-update-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/child-commission-ratio-update-modal.vue").then(m => m.default || m)
  },
  {
    name: "child-merch-agent-child-merch-agent-store",
    path: "/child-merch-agent/child-merch-agent-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/child-merch-agent-store.js").then(m => m.default || m)
  },
  {
    name: "child-merch-agent-child-remark-update-modal",
    path: "/child-merch-agent/child-remark-update-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/child-remark-update-modal.vue").then(m => m.default || m)
  },
  {
    name: "child-merch-agent-page",
    path: "/child-merch-agent/page",
    meta: pagexzA8HTJoz1Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch-agent/page.vue").then(m => m.default || m)
  },
  {
    name: "child-merch-child-merch-store",
    path: "/child-merch/child-merch-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch/child-merch-store.js").then(m => m.default || m)
  },
  {
    name: "child-merch-child-remark-update-modal",
    path: "/child-merch/child-remark-update-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch/child-remark-update-modal.vue").then(m => m.default || m)
  },
  {
    name: "child-merch-page",
    path: "/child-merch/page",
    meta: pageBpcvUuQAd5Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/child-merch/page.vue").then(m => m.default || m)
  },
  {
    name: "home-home-store",
    path: "/home/home-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/home/home-store.js").then(m => m.default || m)
  },
  {
    name: "home-page",
    path: "/home/page",
    meta: pagetvMtt07dyXMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/home/page.vue").then(m => m.default || m)
  },
  {
    name: "im-mobile",
    path: "/im/mobile",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/im/mobile.vue").then(m => m.default || m)
  },
  {
    name: "im-page",
    path: "/im/page",
    meta: page87ZSY9swknMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/im/page.vue").then(m => m.default || m)
  },
  {
    name: "im-pc",
    path: "/im/pc",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/im/pc.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-login-store",
    path: "/login/login-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/login/login-store.js").then(m => m.default || m)
  },
  {
    name: "login-page",
    path: "/login/page",
    meta: pagekKyVX7Y36rMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/login/page.vue").then(m => m.default || m)
  },
  {
    name: "my-commission-my-commission-store",
    path: "/my-commission/my-commission-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/my-commission-store.js").then(m => m.default || m)
  },
  {
    name: "my-commission-page",
    path: "/my-commission/page",
    meta: pageY5sgYAZhsYMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/page.vue").then(m => m.default || m)
  },
  {
    name: "my-commission-withdraw-modal-rmb-store",
    path: "/my-commission/withdraw-modal-rmb-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/withdraw-modal-rmb-store.js").then(m => m.default || m)
  },
  {
    name: "my-commission-withdraw-modal-usdt-store",
    path: "/my-commission/withdraw-modal-usdt-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/withdraw-modal-usdt-store.js").then(m => m.default || m)
  },
  {
    name: "my-commission-WithdrawModalRmb",
    path: "/my-commission/WithdrawModalRmb",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/WithdrawModalRmb.vue").then(m => m.default || m)
  },
  {
    name: "my-commission-WithdrawModalUsdt",
    path: "/my-commission/WithdrawModalUsdt",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-commission/WithdrawModalUsdt.vue").then(m => m.default || m)
  },
  {
    name: "my-fee-my-fee-store",
    path: "/my-fee/my-fee-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-fee/my-fee-store.js").then(m => m.default || m)
  },
  {
    name: "my-fee-page",
    path: "/my-fee/page",
    meta: pagegcfZKCmDZbMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-fee/page.vue").then(m => m.default || m)
  },
  {
    name: "my-merchant-my-merchant-store",
    path: "/my-merchant/my-merchant-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-merchant/my-merchant-store.js").then(m => m.default || m)
  },
  {
    name: "my-merchant-page",
    path: "/my-merchant/page",
    meta: page4YHkNeuMxLMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/my-merchant/page.vue").then(m => m.default || m)
  },
  {
    name: "register-page",
    path: "/register/page",
    meta: pagezcFKVzOmKjMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/register/page.vue").then(m => m.default || m)
  },
  {
    name: "register-register-store",
    path: "/register/register-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/register/register-store.js").then(m => m.default || m)
  },
  {
    name: "settings-page",
    path: "/settings/page",
    meta: pageR3B9rBTmtMMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/settings/page.vue").then(m => m.default || m)
  },
  {
    name: "settings-settings-store",
    path: "/settings/settings-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/settings/settings-store.js").then(m => m.default || m)
  },
  {
    name: "station-letter-detail",
    path: "/station-letter/detail",
    meta: detailyTfRttnybsMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/detail.vue").then(m => m.default || m)
  },
  {
    name: "station-letter-page",
    path: "/station-letter/page",
    meta: pagevrmbmo74EoMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/page.vue").then(m => m.default || m)
  },
  {
    name: "station-letter-station-letter-detail-store",
    path: "/station-letter/station-letter-detail-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/station-letter-detail-store.js").then(m => m.default || m)
  },
  {
    name: "station-letter-station-letter-store",
    path: "/station-letter/station-letter-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/station-letter/station-letter-store.js").then(m => m.default || m)
  },
  {
    name: "test-page",
    path: "/test/page",
    meta: page5z0w2bYwI2Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/test/page.vue").then(m => m.default || m)
  },
  {
    name: "test1-page",
    path: "/test1/page",
    meta: pagenzQOvi1sOOMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/test1/page.vue").then(m => m.default || m)
  },
  {
    name: "test1-test1-store",
    path: "/test1/test1-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/test1/test1-store.js").then(m => m.default || m)
  },
  {
    name: "user-center-page",
    path: "/user-center/page",
    meta: pageEoQZIsAySJMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/user-center/page.vue").then(m => m.default || m)
  },
  {
    name: "user-center-user-center-store",
    path: "/user-center/user-center-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/user-center/user-center-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-bill-page",
    path: "/wallet/usdt-bill/page",
    meta: pageEti39IXmTcMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/wallet/usdt-bill/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-bill-usdt-bill-store",
    path: "/wallet/usdt-bill/usdt-bill-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-agent-backend/pages/wallet/usdt-bill/usdt-bill-store.js").then(m => m.default || m)
  }
]