class routeUtil {
    static async goto(params = {}) {
        useRouter().push(params)
    }

    static async locationHref(path) {
        window.location.href = path;
    }



    static async openNewPage(path) {
        window.open(path)
    }

    static async back() {
        useRouter().back()
    }

    static async replace(params = {}) {
        useRouter().replace(params)
    }

    static getRouter() {
        const router = useRouter();
        if (!router) {
            return {}
        }
        return router.currentRoute.value;
    }

    static getQuery() {
        return routeUtil.getRouter().query;
    }
}

export default routeUtil;
