import {defineStore} from 'pinia';
import browser from "~/utils/browser";
import {vsprintf} from "sprintf-js";
import storage from "~/utils/storage";
import {I18nStore} from "~/common-store/i18n-store";

const LANG_STORAGE_KEY = 'lang';
export const LangStore = defineStore('lang-store-I7jgm', {
    state: () => ({
        defaultLang: browser.getLang(),
    }),
    actions: {
        async setLang(v) {
            await storage.setItem(LANG_STORAGE_KEY, v);
        },
        async _getLang() {
            return await storage.getItem(LANG_STORAGE_KEY);
        },
        async defaultLangUpdate(v) {
            this.defaultLang = v;
            await this.setLang(v);
            window.location.reload();
        },
        async loadLangInfoFromStorage() {
            const langCache = await this._getLang();
            if (langCache) {
                this.defaultLang = langCache;
            }
        },
        //替换
        t(key) {
            const i18nStore = I18nStore();
            const i18n = i18nStore.i18n;

            //如果连key都没有定义, 返回id
            if (!i18n[key]) {
                return key;
            }

            //如果有key, 找不到对应的国际化, 返回id
            if (!i18n[key][this.defaultLang]) {
                return key;
            }
            return i18n[key][this.defaultLang];
        },
        //是否可用
        available(key) {
            const i18nStore = I18nStore();
            const i18n = i18nStore.i18n;

            if (!i18n[key]) {
                return false;
            }

            //如果有key, 找不到对应的国际化, 返回id
            if (!i18n[key][this.defaultLang]) {
                return false;
            }
            return true;
        },

        //根据占位符批量替换
        ts(key, ...args) {
            const i18nStore = I18nStore();
            const i18n = i18nStore.i18n;

            if (!i18n[key]) {
                return key;
            }

            const formatStr = i18n[key][this.defaultLang];
            if (!formatStr) {
                return key;
            }
            return vsprintf(formatStr, args);
        },
    },
});
