<script setup>
import {PageLoadingStore} from "~/common-store/page-loading-store";

const props = defineProps({
  type: {
    type: String,
    default: 'nuxt', //nuxt || a
  },
  to: {
    type: String,
    default: '',
  },
  external: { //是否是外链
    type: Boolean,
    default: false,
  },
  targetBlank: {
    type: Boolean,
    default: false,
  },
});

const pageLoadingStore = PageLoadingStore();

const pageLoadingStart = () => {
  const r = useRoute();

  //没有指定路由 不开启loading
  if (!props.to) {
    return
  }

  //打开新页面 不开启loading
  if (props.targetBlank) {
    return
  }

  //如果路由指向当前页 不开启loading
  if (r.path === props.to || !props.to) {
    return
  }

  pageLoadingStore.start()
}

</script>

<template>
  <NuxtLink
      v-if="type==='nuxt'"
      :to="to"
      :external="external"
      :target="targetBlank?'_blank':''"
      @click="pageLoadingStart"
  >
    <slot/>
  </NuxtLink>
  <a
      v-else
      :href="to"
      :target="targetBlank?'_blank':''"
      @click="pageLoadingStart"
  >
    <slot/>
  </a>

</template>

<style lang="scss" scoped>

</style>
