import {defineStore} from 'pinia';
import storage from "~/utils/storage";
import notify from "~/utils/notify";
import axios from "axios";
import {SUCCESS_CODE} from "~/consts/const";
import find from 'lodash/find';
import {EnvStore} from "~/common-store/env-store";

//todo 定时轮训拉取币种信息
export const CurrencyStore = defineStore('currency-store', {
    state: () => ({
        defaultDecimalNumber: 4,//默认精度
        currencyList: [],
        fetchCurrencyListIng: false,
    }), actions: {
        fetchCurrencyList() {
            if (this.fetchCurrencyListIng) {
                return
            }
            this.fetchCurrencyListIng = true

            const conf = EnvStore().config;

            axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/currency/list`,
            }).then((resp) => {
                const {Code, Data} = resp.data;
                if (Code != SUCCESS_CODE) {
                    notify.err('币种信息异常')
                    return
                }

                this.currencyList = Data;
            }).catch((err) => {
                notify.err('币种信息异常')
                console.log('/api/v1/currency/list', err)
            }).finally(() => {
                this.fetchCurrencyListIng = false
            });
        }, //从currencyList列表中 找出 CurrencyUniqueTag == currency的数据
        // 如果有数据 返回 CurrencyDisplayPrecision, 如果没找到返回默认值defaultDecimalNumber
        currencyDecimal(currency) {
            const currencyUpper = currency.toUpperCase()
            if (this.fetchCurrencyListIng) {
                return this.defaultDecimalNumber
            }

            return find(this.currencyList, {CurrencyUniqueTag: currencyUpper})?.CurrencyDisplayPrecision || this.defaultDecimalNumber
        }
    }
});
