import {StationLetterStore} from "~/pages/station-letter/station-letter-store";
import {LangStore} from "~/common-store/lang-store";
import {UserStore} from '~/store/user-store';

export default defineNuxtRouteMiddleware(async (to, from) => {
    const stationLetterStore = StationLetterStore()
    const userStore = UserStore()
    const hasLogin = userStore.hasLogin()
    console.log(stationLetterStore.fetchUnreadStationLetterInterval)
    if (stationLetterStore.fetchUnreadStationLetterInterval === null) {
        stationLetterStore.fetchUnreadStationLetterInterval = setInterval(async ()=>{
            if (hasLogin) await stationLetterStore.fetchUnreadStationLetter()
        },5000)
    }
})
