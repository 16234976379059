import {defineStore} from 'pinia';
import axios from "~/helpers/axios";
import {EnvStore} from "~/common-store/env-store";
import dayjs from "dayjs";
import storage from "~/utils/storage";
import defaultI18n from '~/assets/default.i18n.json'
import isEmpty from 'lodash/isEmpty'

const I18N_STORAGE_KEY = 'i18n';
export const I18nStore = defineStore('i18n-store-IhGoz', {
    state: () => ({
        i18n: {},
        i18nLoadTime: 0,
        fetchI18nFileIng: false,
    }),
    actions: {
        async loadI18n() {
            const conf = EnvStore().config;

            //先尝试从本地缓存加载
            const i18nCache = await this.loadCache();
            if (!i18nCache) {
                await this.fetchI18nFile();

                //如果拉取失败,使用默认i18n
                if(isEmpty(this.i18n)){
                    this.i18n = defaultI18n;
                    this.i18nLoadTime = dayjs().unix()
                }

                return
            }

            const nowTime = dayjs().unix()
            const diffSec = nowTime - i18nCache.i18nLoadTime
            if (diffSec > conf.I18nReloadIntervalSec) {
                await this.fetchI18nFile();
                return
            }
            console.log(`${conf.I18nReloadIntervalSec - diffSec}s 后可重新加载i18n`)

            this.i18n = i18nCache.i18n;
            this.i18nLoadTime = i18nCache.i18nLoadTime;
        },
        async saveCache(i18nJson, loadTime) {
            console.log('缓存i18n到本地')
            await storage.setItem(I18N_STORAGE_KEY, {
                i18n: i18nJson,
                i18nLoadTime: loadTime
            })
        },
        async loadCache() {
            return await storage.getItem(I18N_STORAGE_KEY)
        },
        async fetchI18nFile() {
            if (this.fetchI18nFileIng) {
                return
            }
            this.fetchI18nFileIng = true

            const conf = EnvStore().config;

            await axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/file-server/i18n/i18n.json?${uuid.id()}`,
            }).then(resp => {
                const nowTime = dayjs().unix()

                this.i18n = resp.data
                this.i18nLoadTime = nowTime

                this.saveCache(resp.data, nowTime)
            }).catch(async (err) => {
                console.log('/api/v1/file-server/i18n/i18n.json', err)
                //如果拉取失败,继续使用缓存的数据
                const i18nCache = await this.loadCache();
                if (!i18nCache) {
                    return;
                }
                this.i18n = i18nCache.i18n;
                this.i18nLoadTime = i18nCache.i18nLoadTime;
            }).finally(() => {
                this.fetchI18nFileIng = false
            })
        },
    },
});
