import {PageLoadingStore} from "~/common-store/page-loading-store";
import {h} from "vue";
import {LoadingOutlined} from '@ant-design/icons-vue';
import {Spin} from 'ant-design-vue';

export default defineNuxtPlugin(nuxtApp => {

    Spin.setDefaultIndicator({
        indicator: h(
            LoadingOutlined,
            {
                style: {
                    fontSize: '30px',
                },
                spin: true,
            },
        ),
    });

    const router = useRouter();
    router.afterEach((to, from, failure) => {
        const pageLoadingStore = PageLoadingStore();
        setTimeout(() => {
            pageLoadingStore.stop();
        }, 2000)
    })
})
