import {LangStore} from "~/common-store/lang-store";

export default defineNuxtPlugin(() => {
    return {
        provide: {
            lang: () => LangStore(),
            //替换
            t: (key) => {
                const langStore = LangStore();
                return langStore.t(key);
            },
            //根据占位符批量替换
            ts: (key, ...args) => {
                const langStore = LangStore();
                langStore.ts(key, ...args);
            },
        }
    }
})
