import {defineStore} from 'pinia';
import {EnvStore} from "~/common-store/env-store";
import dayjs from "dayjs";
import storage from "~/utils/storage";
import axios from "~/helpers/axios";
import {LangStore} from "~/common-store/lang-store";
import uuid from "~/utils/uuid";
import defaultTips from '~/assets/default.tips.json'
import isEmpty from 'lodash/isEmpty'

const TIPS_STORAGE_KEY = 'tips';
export const TipsStore = defineStore('tips-store-FZtBt', {
    state: () => ({
        tips: {},
        tipsLoadTime: 0,
        fetchInfoFileIng: false,
    }),
    actions: {
        async loadTips() {
            const conf = EnvStore().config;

            //先尝试从本地缓存加载
            const tipsCache = await this.loadCache();
            if (!tipsCache) {
                await this.fetchTipsFile();

                //如果拉取失败,使用默认i18n
                if(isEmpty(this.tips)){
                    this.tips = defaultTips;
                    this.tipsLoadTime = dayjs().unix()
                }

                return
            }

            const nowTime = dayjs().unix()
            const diffSec = nowTime - tipsCache.tipsLoadTime
            if (diffSec > conf.TipsReloadIntervalSec) {
                await this.fetchTipsFile();
                return
            }
            console.log(`${conf.TipsReloadIntervalSec - diffSec}s 后可重新加载tips`)

            this.tips = tipsCache.tips;
            this.tipsLoadTime = tipsCache.tipsLoadTime;
        },
        async saveCache(tipsJson, loadTime) {
            console.log('缓存tips到本地')
            await storage.setItem(TIPS_STORAGE_KEY, {
                tips: tipsJson,
                tipsLoadTime: loadTime
            })
        },
        async loadCache() {
            return await storage.getItem(TIPS_STORAGE_KEY)
        },
        async fetchTipsFile() {
            if (this.fetchTipsFileIng) {
                return
            }
            this.fetchTipsFileIng = true

            const conf = EnvStore().config;

            await axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/file-server/tips/tips.json?${uuid.id()}`,
            }).then(resp => {
                const nowTime = dayjs().unix()

                this.tips = resp.data
                this.tipsLoadTime = nowTime

                this.saveCache(resp.data, nowTime)
            }).catch(async (err) => {
                console.log('/api/v1/file-server/tips/tips.json', err)
                //如果拉取失败,继续使用缓存的数据
                const tipsCache = await this.loadCache();
                if(!tipsCache){
                    return;
                }
                this.tips = tipsCache.tips;
                this.tipsLoadTime = tipsCache.tipsLoadTime;
            }).finally(() => {
                this.fetchTipsFileIng = false
            })
        },
        t(k) {
            const langStore = LangStore();
            if (!this.tips[k]) {
                return false;
            }

            const tip = this.tips[k];
            if(!tip[langStore.defaultLang]){
                return false;
            }

            return {
                type: tip.type,
                content: tip[langStore.defaultLang],
            }

        }
    },
});
