import {defineStore} from 'pinia';
import {CalculatorIcon, UsersIcon, BanknotesIcon, CloudIcon} from "@heroicons/vue/24/outline";

export const MainLayoutStore = defineStore('main-layout-store-VmuJA', {
    state: () => ({
        sidebarOpen: false,
        breadcrumbs: [],
        currentSubMenuName: '', //当前的二级菜单, 用于判断是否展开菜单
        menus: [
            {
                name: 'b3O5VyyQ2n',
                href: '/home/page'
            },
            // {name: 'dhjQJlJJU1', tips: 'Gu8R15On5Q', href: '/my-commission/page'},
            // {name: 'ifAOrMCPp0', tips: '6DtHHheHuj', href: '/my-fee/page'},
            // {name: 'HCNFkDeDUz', tips: 'u8MSBAAFG9', href: '/my-merchant/page'},
            {name: 'KImXk213Xp', tips: '', href: '/wallet/usdt-bill/page'},
            // {
            //     name: 'j9xghL6TCY', tips: '',
            //     children: [
            //         {
            //             name: 'jmVbHEil1B',
            //             tips: '',
            //             href: '/child-merch-agent/page',
            //         },
            //     ]
            // },
            {
                name: 'RuxykBls8d', tips: '',
                children: [
                    {
                        name: 'KcnDT1U76Q',
                        tips: '',
                        href: '/child-merch/page',
                    },
                ]
            },
            {
                name: '5fg66b9Z9o',
                href: '/user-center/page',
            },
            //for test
            // {name: '组件测试页面', href: '/test/page', direct: true},
            // {name: '组件测试页面1', href: '/test1/page'},
        ],
    }), getters: {
        homePage() {
            return {
                path: this.menus[0].href
            }
        }
    }, actions: {
        updateCurrentSubMenu(subMenuName) {
            this.currentSubMenuName = subMenuName;
        },
        parentMenuOpen(parentMenu) {
            const subMenus = parentMenu.children || [];
            if (subMenus.length < 1) {
                return false;
            }

            const route = useRoute();

            for (const subMenu of parentMenu.children) {
                //如果页面没有指定 上级, 执行自动匹配规则
                if (route.path === subMenu.href) {
                    //匹配成功 更新当前2级菜单
                    this.currentSubMenuName = subMenu.name
                    return true
                }
            }

            //手动指定 要打开的菜单
            if (!this.currentSubMenuName) {
                return
            }

            for (const subMenu of parentMenu.children) {
                parentMenu.open = true;
                if (this.currentSubMenuName === subMenu.name) {
                    return true
                }
            }

            return false;
        }
    }
});
