<script setup>
import {ref} from "vue";
import {Tooltip} from 'ant-design-vue';

const props = defineProps({
  content: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'leftBottom',
  },
});

const elm = ref();

const getPopupContainer = () => {
  return elm.value;
}

</script>
<template>
  <div class="tooltip-wrapper flex">
    <Tooltip
        :title="props.content"
        class="tooltip"
        :placement="props.placement"
        :getPopupContainer="getPopupContainer"
    >
      <div class="flex" ref="elm">
        <slot/>
      </div>
    </Tooltip>
  </div>
</template>
<style lang="scss" scoped>
.tooltip{

}
.tooltip :deep(.ant-tooltip-inner) {
  background-color: $neutral;
}

.tooltip :deep(.ant-tooltip-arrow::before) {
  background-color: $neutral;
}

</style>
