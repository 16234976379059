import {defineStore} from 'pinia';

export const EnvStore = defineStore('env-store-aWfay', {
    state: () => ({
        loaded: false, config: {
            ENV: '',
            Version: '0.0.0',
            ServerHost: '',
            ImWsServerHost: '',
            ImHttpServerHost: '',
            I18nReloadIntervalSec: 0,
            TipsReloadIntervalSec: 0,
            AdminBackendHost: '',
            MemberBackendHost: '',
            MerchBackendHost: '',
            MerchAgentBackendHost: '',
            TipsHost: '',
            Platform: 0,
        },
    }), actions: {
        loadConfig() {
            if (this.loaded) {
                return
            }
            const runtimeConfig = useRuntimeConfig();
            this.config = {
                ENV: runtimeConfig.public.env,
                Version: runtimeConfig.public.version,
                ServerHost: runtimeConfig.public.serverHost,
                ImWsServerHost: runtimeConfig.public.imWsServerHost,
                ImHttpServerHost: runtimeConfig.public.imHttpServerHost,
                I18nReloadIntervalSec: runtimeConfig.public.i18nReloadIntervalSec,
                TipsReloadIntervalSec: runtimeConfig.public.tipsReloadIntervalSec,
                AdminBackendHost: runtimeConfig.public.adminBackendHost,
                MemberBackendHost: runtimeConfig.public.memberBackendHost,
                MerchBackendHost: runtimeConfig.public.merchBackendHost,
                MerchAgentBackendHost: runtimeConfig.public.merchAgentBackendHost,
                TipsHost: runtimeConfig.public.tipsHost,
                Platform: runtimeConfig.public.platform,
            }
            this.loaded = true;
        }
    },
});

