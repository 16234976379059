import {defineStore} from 'pinia';
import uuid from "~/utils/uuid";
import dayjs from "dayjs";

export const PageLoadingStore = defineStore('page-loading-store-zTGuG', {
    state: () => ({
        loading: true,
        loadingBeginTime: 0,
        loadingID: '',
        minShowMS: 1.5 * 1000,//最小展示毫秒数
        maxShowMS: 10 * 1000, //最多运行毫秒数,到时间后loading自动取消
    }),
    actions: {
        start() {
            this.loading = true;
            this.loadingBeginTime = dayjs().valueOf();
            const loadingID = uuid.id();
            this.loadingID = loadingID;
            setTimeout(() => {
                if (this.loadingID == loadingID) {
                    this.stop(true)
                }
            }, this.maxShowMS)
        },
        stop(force = false) {
            if (force || this.loadingBeginTime === 0) {
                this._reset();
                return
            }

            //剩余展示毫秒数
            const nowTime = dayjs().valueOf();
            const lifeTime = (this.loadingBeginTime + this.minShowMS) - nowTime

            if (lifeTime <= 0) {
                this._reset();
                return
            }

            setTimeout(() => {
                this._reset();
            }, lifeTime)
        },
        _reset() {
            this.loadingID = '';
            this.loadingBeginTime = 0;
            this.loading = false;
        }
    },
});
