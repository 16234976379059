import {defineStore} from 'pinia';
import imClient from '~/utils/im-client';
import {EnvStore} from "~/common-store/env-store";
import uuid from "~/utils/uuid";
import notify from '~/utils/notify'
import {ENV_PRO, SUCCESS_CODE} from "~/consts/const";
import axios from "~/helpers/axios";
import routeUtil from "~/utils/route";
import delay from "delay";
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import {nextTick} from "vue";
import BScroll from "@better-scroll/core";
import isEmpty from 'lodash/isEmpty'

export const ImStore = defineStore('im-store-BgTJq', {
    state: () => ({
        roomsShow: false, //隐藏rooms 菜单

        //选中的room id
        currentRoomId: '',
        selectRoomInfo: {},

        currentUserImOpenId: '',
        currentUsername: '',
        currentUserJwt: '',

        imLoginInfo: {},

        imConnectIng: false,
        imClient: null,

        fetchHistoryMsgByHttpIng: false,
        msgList: {},

        //消息列表滚动
        pullingDownIng: false,
        beforePullDown: true,
        msgListBScrollWrapper: null,
        msgListBScroll: null,

        //会员创建room
        mbrCreateChatRoomIng: false,

        //商户创建room
        merchCreateChatRoomIng: false,

        //商户代理创建room
        merchAgentCreateChatRoomIng: false,

        textMsgContent: '',
        sendTextMsgIng: false,

        sendImgMsgIng: false,

        //加入聊天组
        joinRoomIng: false,

        //客服已加入的聊天组列表
        fetchJoinedRoomsIng: false,
        roomsFilterStr: '',
        joinedRooms: [],

        //客服给room添加备注
        roomRemarkContent: '',
        adminSetRoomRemarkIng: false,
        updateRoomRemarkModalVisible: false,

        //邀请其他客服进入room
        inviteAdminToRoomIng: false,
        inviteAdminToRoomInfo: {
            roomId: '',
            targetAdminUsername: '',
        },
        inviteAdminToRoomModalVisible: false,

        adminRegisterIng: false,
        mbrRegisterIng: false,
        merchRegisterIng: false,
        merchAgentRegisterIng: false,

        //退出聊天组
        adminExitGroupIng: false,

    }),
    getters: {
        filterJoinedRooms() {
            if (this.joinedRooms.length < 1) {
                return [];
            }

            if (!this.roomsFilterStr) {
                return this.joinedRooms;
            }

            const res = [];
            for (const roomInfo of this.joinedRooms) {
                if (roomInfo.CustomerUsername.indexOf(this.roomsFilterStr) > -1) {
                    res.push(roomInfo)
                }
            }

            return res;
        },
    },
    actions: {
        async adminImLogin() {
            const conf = EnvStore().config;

            for (let i = 0; i < 10; i++) {
                const accessToken = await axios({
                    method: 'post',
                    url: `${conf.ServerHost}/api/v1/ab/im/login`,
                }).then((resp) => {
                    if (resp.data.Code !== SUCCESS_CODE) {
                        return false;
                    }

                    return resp.data.Data;
                }).catch((err) => {
                    console.log('/api/v1/ab/im/login', err);
                    return false;
                });

                if (accessToken) {
                    this.imLoginInfo = accessToken
                    return;
                }

                await delay(3000);
            }
        },
        async mbrImLogin() {
            const conf = EnvStore().config;

            for (let i = 0; i < 10; i++) {
                const accessToken = await axios({
                    method: 'post',
                    url: `${conf.ServerHost}/api/v1/mbr/im/login`,
                }).then((resp) => {
                    if (resp.data.Code !== SUCCESS_CODE) {
                        return false;
                    }

                    return resp.data.Data;
                }).catch((err) => {
                    console.log('/api/v1/mbr/im/login', err);
                    return false;
                });

                if (accessToken) {
                    this.imLoginInfo = accessToken
                    return;
                }

                await delay(3000);
            }
        },
        async merchImLogin() {
            const conf = EnvStore().config;

            for (let i = 0; i < 10; i++) {
                const accessToken = await axios({
                    method: 'post',
                    url: `${conf.ServerHost}/api/v1/merch/im/login`,
                }).then((resp) => {
                    if (resp.data.Code !== SUCCESS_CODE) {
                        return false;
                    }

                    return resp.data.Data;
                }).catch((err) => {
                    console.log('/api/v1/merch/im/login', err);
                    return false;
                });

                if (accessToken) {
                    this.imLoginInfo = accessToken
                    return;
                }

                await delay(3000);
            }
        },
        async merchAgentImLogin() {
            const conf = EnvStore().config;

            for (let i = 0; i < 10; i++) {
                const accessToken = await axios({
                    method: 'post',
                    url: `${conf.ServerHost}/api/v1/merchab/im/login`,
                }).then((resp) => {
                    if (resp.data.Code !== SUCCESS_CODE) {
                        return false;
                    }

                    return resp.data.Data;
                }).catch((err) => {
                    console.log('/api/v1/merchab/im/login', err);
                    return false;
                });

                if (accessToken) {
                    this.imLoginInfo = accessToken
                    return;
                }

                await delay(3000);
            }
        },
        async imConnect(imOpenId, username, debug = false) {
            if (isEmpty(this.imLoginInfo)) {
                return
            }
            this.currentUserImOpenId = imOpenId
            this.currentUsername = username

            if (this.imClient) {
                return
            }

            if (this.imConnectIng) {
                return
            }
            this.imConnectIng = true

            await delay(1000)

            const conf = EnvStore().config;

            const im = new imClient({
                wsAddr: conf.ImWsServerHost,
                httpAddr: conf.ImHttpServerHost,
                username: username,
                imOpenId: imOpenId,
                sessionId: uuid.id(),
                loginInfo: this.imLoginInfo,
                personDebug: debug,
            }).setDebug(conf.ENV !== ENV_PRO);

            const {error, data} = await im.connect();
            this.imConnectIng = false;

            if (error) {
                notify.err('im connect fail');
                return false;
            }

            im.setOnReceiveMsgFunc(
                this.onReceiveMsgCallbackFunc
            )

            this.imClient = im
            return true
        },
        pushMsg(roomId, msg) {
            if (has(this.msgList, roomId)) {
                this.msgList[roomId].push(msg)
                return
            }

            this.msgList[roomId] = [msg]
        },
        //向头部添加数据
        pushMsgArr(roomId, msgs) {
            let currentMsgs = [];
            if (has(this.msgList, roomId)) {
                currentMsgs = this.msgList[roomId];
            }

            currentMsgs.push(...msgs)

            const uniqueMsgs = uniqBy(currentMsgs, 'msg_id');
            const sortedMsgs = orderBy(uniqueMsgs, ['send_time'], ['asc']);

            this.msgList[roomId] = sortedMsgs
        },
        //会员创建room
        async mbrCreateChatRoom() {
            if (this.mbrCreateChatRoomIng) {
                return
            }
            this.mbrCreateChatRoomIng = true

            await delay(300)

            const conf = EnvStore().config;
            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/mbr/im/chat-room/create`,
            }).then(async (resp) => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }

                await delay(2000)
                routeUtil.goto('/im/page')

            }).catch(e => {
                notify.err('联系客服失败')
                console.log('/api/v1/mbr/im/chat-room/create', e)
            }).finally(() => {
                this.mbrCreateChatRoomIng = false
            })
        },
        //商户创建room
        async merchCreateChatRoom() {
            if (this.merchCreateChatRoomIng) {
                return
            }
            this.merchCreateChatRoomIng = true

            await delay(300)

            const conf = EnvStore().config;
            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/merch/im/chat-room/create`,
            }).then(async (resp) => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }

                await delay(2000)
                routeUtil.goto('/im/page')

            }).catch(e => {
                notify.err('联系客服失败')
                console.log('/api/v1/merch/im/chat-room/create', e)
            }).finally(() => {
                this.merchCreateChatRoomIng = false
            })
        },
        //商户代理创建room
        async merchAgentCreateChatRoom() {
            if (this.merchAgentCreateChatRoomIng) {
                return
            }
            this.merchAgentCreateChatRoomIng = true

            await delay(300)

            const conf = EnvStore().config;
            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/merchab/im/chat-room/create`,
            }).then(async (resp) => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }

                await delay(2000)
                routeUtil.goto('/im/page')

            }).catch(e => {
                notify.err('联系客服失败')
                console.log('/api/v1/merchab/im/chat-room/create', e)
            }).finally(() => {
                this.merchAgentCreateChatRoomIng = false
            })
        },
        async sendTextMsg(roomId, imOpenId, msg) {
            if (!this.textMsgContent) {
                return
            }

            if (!this.imClient) {
                return
            }

            this.sendTextMsgIng = true
            await delay(2000)
            this.imClient.sendTextMsg(
                roomId, msg,
            ).then(() => {
                this.textMsgContent = '';
            }).finally(() => {
                this.sendTextMsgIng = false
            });

        },
        async sendImgMsg(f, roomId) {
            if (this.sendImgMsgIng) {
                return;
            }
            this.sendImgMsgIng = true;

            await delay(300)

            const formData = new FormData();
            formData.append('file', f);
            formData.append('roomId', roomId);

            const conf = EnvStore().config;

            await axios({
                method: 'post',
                url: `${conf.ServerHost}/api/v1/file-server/s3/im/admin/img/upload`,
                data: formData,
            }).then((resp) => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp);
                    return;
                }

                this.imClient.sendGroupImgMsg(roomId, resp.data.Data);
            })
                .catch((err) => {
                    notify.err('图片上传失败');
                    console.log('/api/v1/im/admin/img/upload', err);
                })
                .finally(() => {
                    this.sendImgMsgIng = false;
                });
        },
        async fetchHistoryMsgByHttp(roomId) {
            if (this.fetchHistoryMsgByHttpIng) {
                return
            }
            this.fetchHistoryMsgByHttpIng = true

            await delay(300)

            let sendTime = 0;
            if (has(this.msgList, roomId)) {
                sendTime = this.msgList[roomId][0].send_time;
            }

            await this.imClient.fetchHistoryMsgByHttp(
                roomId, sendTime,
            ).then((msgs) => {
                if (!isArray(msgs)) {
                    notify.err('历史消息拉取失败');
                    return;
                }

                this.pushMsgArr(roomId, msgs)
            }).catch((err) => {
                notify.err('历史消息拉取失败');
                console.log('fetchHistoryMsgByHttp', err);
            }).finally(() => {
                this.fetchHistoryMsgByHttpIng = false;
            });
        },
        //加入聊天室
        async joinRoom(imOpenId,record) {
            if (record.joinRoomIng) {
                return
            }
            record.joinRoomIng = true

            await delay(300)

            const conf = EnvStore().config;

            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/ab/im/room/join`,
                data: {
                    RoomId: record.GroupId,
                }
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.err("加入聊天组失败")
                    return
                }

                notify.success("加入聊天组成功")

            }).catch(e => {
                notify.err('加入聊天组失败')
                console.log('/api/v1/ab/im/room/join', e)
            }).finally(() => {
                record.joinRoomIng = false
            })
        },
        //获取已加入的聊天组
        async fetchAdminJoinedRooms() {
            if (this.fetchJoinedRoomsIng) {
                return
            }
            this.fetchJoinedRoomsIng = true

            await delay(300)

            const conf = EnvStore().config;

            axios({
                method: "get",
                url: `${conf.ServerHost}/api/v1/ab/im/joined-rooms/list`,
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.err("获取已加入聊天组失败")
                    return
                }

                const rooms = resp.data.Data || []
                const sortRooms = orderBy(
                    rooms,
                    ['HasUnReadMessage', 'CustomerUsername', 'CustomerUserType'],
                    ['desc', 'asc', 'asc'],
                );

                this.joinedRooms = sortRooms;

            }).catch(e => {
                notify.err('获取已加入聊天组失败')
                console.log('/api/v1/ab/im/joined-rooms/list', e)
            }).finally(() => {
                this.fetchJoinedRoomsIng = false
            })
        },
        //获取已加入的聊天组
        async adminSetRoomRemark() {
            if (this.adminSetRoomRemarkIng) {
                return
            }
            this.adminSetRoomRemarkIng = true

            await delay(300)

            const conf = EnvStore().config;

            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/ab/im/session/remark/update`,
                data: {
                    RoomId: this.currentRoomId,
                    Remark: this.roomRemarkContent,
                },
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.err("操作失败")
                    return false
                }

                const room = find(this.joinedRooms, (v) => {
                    return v.GroupId === this.currentRoomId
                });
                if (room) {
                    room.Remark = this.roomRemarkContent
                }

                this.updateRoomRemarkModalHide()

                return true
            }).catch(e => {
                notify.err('操作失败')
                console.log('/api/v1/ab/im/session/remark/update', e)
            }).finally(() => {
                this.adminSetRoomRemarkIng = false
            })
        },
        updateRoomRemarkModalShow(currentRemark) {
            this.roomRemarkContent = currentRemark;
            this.updateRoomRemarkModalVisible = true
        },
        updateRoomRemarkModalHide() {
            this.updateRoomRemarkModalVisible = false
        },
        adminRegister() {
            if (this.adminRegisterIng) {
                return
            }
            this.adminRegisterIng = true;

            const conf = EnvStore().config;

            axios({
                method: 'post',
                url: `${conf.ServerHost}/api/v1/ab/im/register`,
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
            }).catch((err) => {
                console.log('/api/v1/ab/im/register`', err);
                notify.err("IM注册失败");
            }).finally(() => {
                this.adminRegisterIng = false;
            })
        },
        async mbrRegister() {
            if (this.mbrRegisterIng) {
                return
            }
            this.mbrRegisterIng = true;

            const conf = EnvStore().config;

            await axios({
                method: 'post',
                url: `${conf.ServerHost}/api/v1/mbr/im/register`,
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
            }).catch((err) => {
                console.log('/api/v1/mbr/im/register`', err);
                notify.err("IM注册失败");
            }).finally(() => {
                this.mbrRegisterIng = false;
            })
        },
        merchRegister() {
            if (this.merchRegisterIng) {
                return
            }
            this.merchRegisterIng = true;

            const conf = EnvStore().config;

            axios({
                method: 'post',
                url: `${conf.ServerHost}/api/v1/merch/im/register`,
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
            }).catch((err) => {
                console.log('/api/v1/merch/im/register`', err);
                notify.err("IM注册失败");
            }).finally(() => {
                this.merchRegisterIng = false;
            })
        },
        merchAgentRegister() {
            if (this.merchAgentRegisterIng) {
                return
            }
            this.merchAgentRegisterIng = true;

            const conf = EnvStore().config;

            axios({
                method: 'post',
                url: `${conf.ServerHost}/api/v1/merchab/im/register`,
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
            }).catch((err) => {
                console.log('/api/v1/merchab/im/register`', err);
                notify.err("IM注册失败");
            }).finally(() => {
                this.merchAgentRegisterIng = false;
            })
        },
        async selectRoom(roomId) {
            this.selectRoomInfo = {}

            this.currentRoomId = roomId

            const room = find(this.joinedRooms, (v) => {
                return v.GroupId === roomId
            });
            if (!room) {
                return
            }

            this.selectRoomInfo = room
        },
        //清理room已读状态
        async adminRoomUnreadFlagClean(roomId) {
            if (this.roomUnreadFlagCleanIng) {
                return
            }
            this.roomUnreadFlagCleanIng = true

            await delay(3000)

            const conf = EnvStore().config;

            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/ab/im/clean-un-read-status`,
                data: {
                    RoomId: roomId,
                }
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.err("已读状态更新失败")
                    return
                }

                for (const index in this.joinedRooms) {
                    const room = this.joinedRooms[index]
                    if (room.GroupId === roomId) {
                        this.joinedRooms[index].HasUnReadMessage = false
                    }
                }

            }).catch(e => {
                notify.err('已读状态更新失败')
                console.log('/api/v1/ab/im/clean-un-read-status', e)
            }).finally(() => {
                this.roomUnreadFlagCleanIng = false
            })
        },
        inviteAdminToRoomModalShow(roomId) {
            this.inviteAdminToRoomInfo = {roomId}
            this.inviteAdminToRoomModalVisible = true;
        },
        inviteAdminToRoomModalHide() {
            this.inviteAdminToRoomInfo = {
                roomId: '',
                targetAdminUsername: '',
            }
            this.inviteAdminToRoomModalVisible = false;
        },
        async inviteAdminToRoom() {
            if (this.inviteAdminToRoomIng) {
                return
            }
            this.inviteAdminToRoomIng = true

            await delay(3000)

            const conf = EnvStore().config;

            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/ab/im/invite-admin-to-room`,
                data: {
                    GroupId: this.inviteAdminToRoomInfo.roomId,
                    TargetAdminUsername: this.inviteAdminToRoomInfo.targetAdminUsername,
                },
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.err("拉取其他客服失败")
                    return
                }

                notify.success("拉取其他客服成功")

                this.inviteAdminToRoomModalHide()
            }).catch(e => {
                notify.err('拉取其他客服失败')
                console.log('/api/v1/ab/im/invite-admin-to-room', e)
            }).finally(() => {
                this.inviteAdminToRoomIng = false
            })
        },
        async adminExitGroup(groupId, imOpenId) {
            if (!groupId) {
                notify.err("聊天组ID异常")
                return
            }

            if (!imOpenId) {
                notify.err("im openid 异常")
                return
            }

            if (this.adminExitGroupIng) {
                return
            }
            this.adminExitGroupIng = true

            await delay(3000)

            const conf = EnvStore().config;

            axios({
                method: "post",
                url: `${conf.ServerHost}/api/v1/ab/im/room/exit`,
                data:{
                    RoomId:groupId,
                }
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.err("退出聊天组失败")
                    return
                }

                notify.success("退出聊天组成功")

                this.msgList[groupId] = [];
                this.currentRoomId = '';
                this.fetchAdminJoinedRooms()

            }).catch(e => {
                notify.err('退出聊天组失败')
                console.log('/api/v1/ab/im/room/exit', e)
            }).finally(() => {
                this.adminExitGroupIng = false
            })
        },
        async initBScroll() {
            if (this.msgListBScroll) {
                this.msgListBScroll.destroy()
                await delay(300)
            }

            this.msgListBScroll = new BScroll(
                this.msgListBScrollWrapper,
                {
                    scrollY: true,
                    bounceTime: 800,
                    useTransition: false,
                    pullDownRefresh: {
                        threshold: 70,
                        stop: 56
                    }
                },
            );

            this.msgListBScroll.on('pullingDown', async () => {
                if (this.pullingDownIng) {
                    return
                }
                this.pullingDownIng = true

                if (this.fetchHistoryMsgByHttpIng) {
                    return
                }

                this.beforePullDown = false

                await this.fetchHistoryMsgByHttp(
                    this.currentRoomId,
                )

                this.pullingDownIng = false;

                this.msgListBScroll.finishPullDown()
                setTimeout(() => {
                    this.beforePullDown = true
                    this.msgListBScroll.refresh()
                }, 800 + 100)
            })
        },
        async scrollToBottom(force = false, recompute = false, delayMS = 0) {
            if (!this.msgListBScroll || recompute) {
                await nextTick();
                this.initBScroll();
                await delay(300)
            }

            await nextTick();
            if (delayMS < 300) {
                delayMS = 300;
            }
            await delay(delayMS);

            this.msgListBScroll.refresh();

            // 如果不在最底部 不进行滚动
            if (!force && this.msgListBScroll.y > this.msgListBScroll.maxScrollY + 100) {
                return;
            }

            this.msgListBScroll.scrollTo(
                0,
                this.msgListBScroll.maxScrollY - 10,
                1000,
            );
        },
        async onReceiveMsgCallbackFunc({roomId, msg}) {
            this.pushMsg(roomId, msg)

            await delay(500)

            await this.scrollToBottom(true);
        },
        async close() {
            if (this.imClient) {
                await this.imClient.close()
            }

            this.$reset()
        }
    }
});
