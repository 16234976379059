import localforage from 'localforage';

class storage {
  static async getItem(k) {
    return localforage.getItem(k);
  }

  static async setItem(k, v) {
    return localforage.setItem(k, v);
  }

  static async removeItem(k) {
    return localforage.removeItem(k);
  }
}

export default storage;
